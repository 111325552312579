<template>
  <div style="display: flex">
    <el-button v-if="isShowBtn"
      @click="getTableConf(2)"
      type="primary"
      plain
      style="margin-left: 10px; height: 40px"
    >
      <i class="iconfont iconliebiao1"></i>打印配置
    </el-button>
    <el-dialog
      :visible.sync="tableVisiable"
      title="打印配置"
      width="30%"
      :destroy-on-close="true"
      :before-close="cancelTable"
    >
      <el-button
        type="primary"
        plain
        style="margin-bottom: 20px"
        @click="handleReset"
        >恢复默认</el-button
      >
      <span style="color: #F56C6C; margin-left: 10px;">*可拖拽行改变列表排序，所选打印字段宽度之和不能超过210mm。</span>
      <div class="table" style="border: 1px solid #ddd">
        <div style="height: 400px; overflow-y: auto;">
        <ux-grid
                border
                row-key
                ref="plxTable"
                :data="rowList">
            <ux-table-column
                    v-for="item in tableColumn"
                    align="center"
                    :key="item.id"
                    :resizable="item.resizable"
                    :field="item.field"
                    :title="item.title"
                    :sortable="item.sortable"
                    :width="item.minWidth"
                    :fixed="item.fixed">
                    <template v-slot="scope" v-if="item.field === 'width'">
                      <el-input :disabled="scope.row.disabled? true: false" v-model="scope.row.width"></el-input>
                    </template>
            </ux-table-column>
            <ux-table-column title="是否打印" align="center" field="is_use">
              <template  v-slot="scope">
                <el-switch
                :disabled="scope.row.disabled? true: false"
                v-model="scope.row.is_use"
                :active-value="1"
                :inactive-value="0"
              ></el-switch>
              <!-- :disabled="
                  scope.row.field_alias === 'entry_number' ? true : false
                " -->
              </template>
            </ux-table-column>
        </ux-grid>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="tableVisiable = false">取 消</el-button>
        <el-button type="primary" @click="handleTrue" :loading="loading"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Sortable from 'sortablejs/modular/sortable.core.esm.js';
import { tableConfReq, tableConfSet, restoreReq } from "./index";

export default {
  name: "TableConf",
  data() {
    return {
      tableData: [],
      tableColumn: [
        { title: '表头字段', field: 'field_text' },
        { title: '宽度(mm)', field: 'width' },
        // { title: '是否打印', field: 'is_use' },
      ],
      loading: false,
      coordinate: {},
      tableVisiable: false,
      rowList: [],
      usedList: [],
      noUsedList: [],
    };
  },
  watch: {
    tableVisiable: {
      handler(val) {
        if (val) {
          this.columnDrop();
          this.columnDrop2();
          this.columnDrop3();
          this.columnDrop4();
        }
      },
      immediate: true,
    },
    mode: {
      handler() {
        this.getTableConf(1);
      },
    },
  },
  props: {
    type:{ //类型 1:默认 2.调用父方法
      type:String|Number,
      default:1,
    },
    isShowBtn:{ //是否显示按钮
      type:Boolean,
      default:true,
    },
    function_code: String,
    mode: Number,
    tab: {
      type: Number,
      default() {
        return 0;
      },
    },
  },
  created() {
    this.getTableConf(1);
  },
  mounted() {
   
  },
  // beforeDestroy () {
  //     if (this.sortable) {
  //         this.sortable.destroy()
  //     }
  // },
  methods: {
    columnDrop () {
      this.$nextTick(() => {
          let plxTable = this.$refs.plxTable;
          if (!document.getElementsByClassName('elx-table--body')[0]) {
            return;
          }
          const tbodyDom = document.getElementsByClassName('elx-table--body')[0].getElementsByTagName("tbody")[0];
          // 关于sortable的配置https://www.cnblogs.com/xiangsj/p/6628003.html
          this.sortable = Sortable.create(tbodyDom, {
              handle: ".elx-body--row",  // Drag handle selector within list items
              // 拖拽结束
              onEnd: ({ item, newIndex, oldIndex }) => {
                  let currRow = this.rowList.splice(oldIndex, 1)[0]
                  this.rowList.splice(newIndex, 0, currRow)
              }
          })
      })
    },
    columnDrop2 () {
      this.$nextTick(() => {
          let plxTable = this.$refs.plxTable
          if (!document.getElementsByClassName('elx-table--body')[1]) {
            return;
          }
          const tbodyDom = document.getElementsByClassName('elx-table--body')[1].getElementsByTagName("tbody")[0];
          // 关于sortable的配置https://www.cnblogs.com/xiangsj/p/6628003.html
          this.sortable = Sortable.create(tbodyDom, {
              handle: ".elx-body--row",  // Drag handle selector within list items
              // 拖拽结束
              onEnd: ({ item, newIndex, oldIndex }) => {
                  let currRow = this.rowList.splice(oldIndex, 1)[0]
                  this.rowList.splice(newIndex, 0, currRow)
              }
          })
      })
    },
    columnDrop3 () {
      this.$nextTick(() => {
          let plxTable = this.$refs.plxTable
          if (!document.getElementsByClassName('elx-table--body')[2]) {
            return;
          }
          const tbodyDom = document.getElementsByClassName('elx-table--body')[2].getElementsByTagName("tbody")[0];
          // 关于sortable的配置https://www.cnblogs.com/xiangsj/p/6628003.html
          this.sortable = Sortable.create(tbodyDom, {
              handle: ".elx-body--row",  // Drag handle selector within list items
              // 拖拽结束
              onEnd: ({ item, newIndex, oldIndex }) => {
                  let currRow = this.rowList.splice(oldIndex, 1)[0]
                  this.rowList.splice(newIndex, 0, currRow)
              }
          })
      })
    },
    columnDrop4 () {
      this.$nextTick(() => {
          let plxTable = this.$refs.plxTable
          if (!document.getElementsByClassName('elx-table--body')[3]) {
            return;
          }
          const tbodyDom = document.getElementsByClassName('elx-table--body')[3].getElementsByTagName("tbody")[0];
          // 关于sortable的配置https://www.cnblogs.com/xiangsj/p/6628003.html
          this.sortable = Sortable.create(tbodyDom, {
              handle: ".elx-body--row",  // Drag handle selector within list items
              // 拖拽结束
              onEnd: ({ item, newIndex, oldIndex }) => {
                  let currRow = this.rowList.splice(oldIndex, 1)[0]
                  this.rowList.splice(newIndex, 0, currRow)
              }
          })
      })
    },
    handleReset() {
      this.$confirm("确定要重置列表配置吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.loading = true;
        restoreReq(this.function_code)
          .then((res) => {
            if (res.code === 1) {
              this.loading = false;
              this.tableVisiable = false;
              this.getTableConf(1);
              this.$message.success("重置成功");
            }
          })
          .catch(() => {
            this.loading = false;
          });
      });
    },
    handleBlur() {
      this.coordinate = {
        column: -1,
        row: -1,
      };
    },
    // 数组交换位置
    handleArrSort(sort1, sort2) {
      this.rowList.splice(
        sort1,
        1,
        ...this.rowList.splice(sort2, 1, this.rowList[sort1])
      );
      this.rowList.forEach((item, index) => {
        item.t_order = this.rowList.length - index;
      });
    },
    handleInput(e) {
      const a = e.key.replace(/[^\d]/g, "");
      if (!a && e.keyCode !== 8) {
        e.preventDefault();
      }
    },
    handleTrue() {
      let width = 0;
      let widthErro;
      console.log(this.rowList);
      this.rowList.forEach((item, index) => {
        item.t_order = this.rowList.length - index;
        if (item.is_use === 1) {
          width += Number(item.width);
        }
        if (width > 210) {
          widthErro = true;
        }
      });
      if (widthErro) {
        this.$message.warning('所选打印字段宽度之和不能超过210mm。');
        return;
      }
      this.loading = true;
      // 需要重新渲染表格
      // this.$emit("onRowList", []);
      tableConfSet({
        function_code: this.function_code,
        data: this.rowList,
        tab: this.tab,
        mode: this.mode,
      })
        .then((res) => {
          if (res.code === 1) {
            this.loading = false;
            this.tableVisiable = false;
            if(this.type==2){
              console.log(this.type)
              // this.$parent.isShowPrint()
            }
            this.getTableConf(1);
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    cancelTable() {
      const coordinate = { ...this.coordinate };
      coordinate.row = -1;
      coordinate.column = -1;
      this.coordinate = coordinate;
      this.tableVisiable = false;
    },
    hanldeCellClick(row, column) {
      const coordinate = { ...this.coordinate };
      coordinate.row = row.index;
      coordinate.column = column.index;
      this.coordinate = coordinate;
      const dom = document.getElementById("input" + row.index + column.index);
      this.$nextTick(() => {
        try {
          dom.focus();
        } catch (error) {
          // console.log('无法聚焦');
        }
      });
    },
    getCellIndex({ row, column, rowIndex, columnIndex }) {
      row.index = rowIndex;
      column.index = columnIndex;
    },
    // 获取列表配置项
    getTableConf(type) {
      tableConfReq(this.function_code, this.tab, this.mode).then((res) => {
        if (res.code === 1) {
          // 将列表配置分割为使用和未使用的
          const { data } = res;
          data.unshift({
            field: 'index',
            field_alias: 'index',
            field_text: "序号",
            is_goods: 0,
            is_sum: 0,
            is_use: 1,
            width: 10,
            disabled: true,
          });
          if (type === 1) {
            this.usedList = data.filter((item) => item.is_use);
            this.noUsedList = data.filter((item) => !item.is_use);
            this.$emit("onRowList", this.usedList);
            this.rowList = JSON.parse(JSON.stringify(data));
          } else {
            this.tableVisiable = true;
            this.rowList = JSON.parse(JSON.stringify(data));
          }
        }
      });
    },
  },
};
</script>

<style scoped>
.cutline {
  margin: 0 20px;

  width: 1px;
  height: 40px;
  background: #e9e9e9;
}
</style>
